export enum FlexBillingRoutes {
    AllPendingOrders = 'allpendingorders',
    Customers = 'customers',
    CustomerAdd = 'customer-add',
    CustomerEdit = 'customer-edit',
    CustomerView = 'customer-view',
    Products = 'products',
    ProductEdit = 'product-edit',
    FlexBilling = 'flex-billing',
    ProcessLog = 'process-log',
}
