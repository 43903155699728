export enum UserRoleType {
    SysAdmin = 'SysAdmin',
    BillingAdmin = 'BillingAdmin',
    ReadOnly = 'ReadOnly',
}

export enum CustomerViewRoutes {
    FlexBalance = 'flexbalance',
    Comments = 'comments',
    PurchasePlans = 'purchaseplans',
    PurchasePlanEdit = 'purchaseplanedit',
    PurchasePlanReadonly = 'purchaseplanreadonly',
    ProcessLogs = 'processlogs',
}

export enum ValidationDisplayErrors {
    DateValidationError = 'date-validation-error',
    InputValidationError = 'input-validation-error',
}

export enum ValidationAttributes {
    ValidationFor = 'validation-for',
    ValidationMessage = 'validation-message',
    ValidateIfNotEmpty = 'validate-if-not-empty',
    ValidateRegEx = 'validate-reg-ex',
}

export enum FlexBillingSourceCss {
    Override = 'purchase-plan-override-status',
    Placed = 'purchase-plan-placed-status',
    Cancelled = 'purchase-plan-cancelled-status',
    Replaced = 'purchase-plan-replaced-status',
    Completed = 'purchase-plan-completed-status',
    Realized = 'purchase-plan-realized-status',
    Automatic = 'purchase-plan-automatic-status',
    Manual = 'purchase-plan-manual-status',
}

export enum ProcessLogSourceCss {
    Running = 'process-log-running-status',
    Success = 'process-log-success-status',
    Failed = 'process-log-failed-status',
}

export enum PuchasePlanContactType {
    CustomerServiceManager = 'CustomerServiceManager',
    AccountManager = 'AccountManager',
}

export enum DragDropCssClassTypes {
    DragOver = 'drag-over',
    DragLeave = 'drag-leave',
    DragError = 'drag-error',
    DragSuccess = 'drag-success',
    DragIdle = '',
}

export enum CopyProductBehaviorTypes {
    CopyAllProducts = 'Copy all Products from previous Purchase Plan',
    ResetFlexCategories = 'Reset Flex Categories to standard valuues',
    ResetPrices = 'Reset prices to standard prices',
    RemovePricingTiers = 'Remove Pricing Tiers for Products',
    RemoveRushFeeOverrides = 'Remove Rush Fee Overrides for Products',
}
