import { DOMPurifySanitizer } from '@venminder/vm-library';
import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { HTMLSanitizer } from 'aurelia-templating-resources';
import environment from '../config/environment.json';
import { DateTimeKind, installDatePolyfills } from './venminder-default/shared/utilities/date-helpers';

installDatePolyfills();

declare global {
    interface Array<T> {
        fill(value: T, start?: number, end?: number): this;
    }
    interface Date {
        kind: DateTimeKind;

        getTimezoneOffset_Save(): number;
        toString(format?: string): string;
        specifyKind(kind: DateTimeKind): Date;
        createDate(dateTimeString?: string, dateTimeKind?: DateTimeKind): Date;
        addDays(days: number): Date;
        subtractDays(days: number): Date;
        addMonths(days: number): Date;
        convertFromUTCtoLocal(): void;
    }
    interface String {
        hashCode(): number;
    }
    interface JQuery {
        tipTip(options: any, value: any): JQuery;
    }
    interface AdBlockDetector {
        init(options: { debug: boolean; complete?: (findResult: boolean) => void; found?: () => void; notFound?: () => void }): void;
    }
    var adblockDetector: AdBlockDetector;
}
export function configure(aurelia: Aurelia): void {
    aurelia.use
        .standardConfiguration()
        .feature(PLATFORM.moduleName('venminder-default/resources/index'))
        .plugin(PLATFORM.moduleName('aurelia-bootstrap', 'global'))
        .plugin(PLATFORM.moduleName('aurelia-dialog'))
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .plugin(PLATFORM.moduleName('@venminder/vm-library'))
        .singleton(HTMLSanitizer, DOMPurifySanitizer);

    aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

    if (environment.testing) {
        aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }

    // Grid to use for now until we adopt vm-grid v3
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-slickgrid'));

    aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
