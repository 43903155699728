import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { getRequiredVenminderRoles } from 'shared/utilities/role-utils';
import { AuthorizeStep } from 'venminder-default/shared/pipeline-steps/authorize-step';
import { FlexBillingRoutes } from './shared/constants/flex-billing-routes';
import { UserRoleType } from './shared/enums/flex-billing.enums';

export class App {
    private router: Router;

    configureRouter(config: RouterConfiguration, router: Router) {
        config.addAuthorizeStep(AuthorizeStep);

        config.options.pushState = true;
        config.options.root = '/';

        config.map([
            { route: '', redirect: FlexBillingRoutes.FlexBilling },
            {
                route: FlexBillingRoutes.FlexBilling,
                moduleId: PLATFORM.moduleName('areas/flex-billing/flex-billing.component', 'flex-billing'),
                settings: {
                    roles: [UserRoleType.SysAdmin, UserRoleType.BillingAdmin, UserRoleType.ReadOnly, 'DC'],
                    venminderRoles: getRequiredVenminderRoles(),
                    auth: true,
                },
            },
            {
                route: 'user/auth/oidc/signin-redirect',
                name: 'signin-redirect',
                moduleId: PLATFORM.moduleName('venminder-default/user/components/auth/oidc/signin-redirect', 'user-shared'),
            },
            {
                route: 'user/auth/oidc/refresh-redirect',
                name: 'refresh-redirect',
                moduleId: PLATFORM.moduleName('venminder-default/user/components/auth/oidc/refresh-redirect', 'user-shared'),
            },
            {
                route: 'user/auth/oidc/signout-redirect',
                name: 'signout-redirect',
                moduleId: PLATFORM.moduleName('venminder-default/user/components/auth/oidc/signout-redirect', 'user-shared'),
            },
        ]);

        this.router = router;
    }
}
