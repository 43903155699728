export namespace EventNames {
    export namespace Api {
        export const API_ERROR_DIALOG_OPEN = 'Api:Error:Dialog:Open';
        export const API_ERROR_DIALOG_CLOSED = 'Api:Error:Dialog:Closed';
    }
    export namespace Templates {
        export const TEMPLATE_SECTION_MODIFIED = 'Templates:Section:Modified';
        export const TEMPLATE_SECTIONS_SUBHEADER = 'Templates:Sections:Subheader';
        export const TEMPLATE_SECTIONS_CONTROL = 'Templates:Sections:Control';
        export const TEMPLATE_DELETE_SECTION = 'Templates:DeleteSection';
        export const TEMPLATE_SECTION_INVALID = 'Template:Sections:Invalid';
        export const TEMPLATE_SUBHEADER_INVALID = 'Template:Sections:Subheader:Invalid';
        export const TEMPLATE_CONTROL_INVALID = 'Template:Sections:Control:Invalid';
    }
    export namespace Controls {
        export const REFRESH_CONTROL_LIST = 'Controls:RefreshList';
        export const ANSWER_LIST_CHANGED = 'Controls:AnswerList:Changed';
        export const CARET_BUTTON_CLICKED = 'Controls:CaretButtonClicked';
    }
}